import { Component, Prop, Vue } from 'vue-property-decorator';
import { Accessors } from 'vue/types/options';

@Component
export default class HasRank extends Vue {
    get rank (): null | number {
        return null
    }
    get rankColor () {
        if (!this.rank || !this.$config) {
            return ''
        }
        const res = this.rank / this.$config.nbTrooperz
        if (res < 0.05) {
            return '#E6A529'
        } else if (res < 0.2) {
            return '#B429E6'
        } else if (res < 0.45) {
            return 'aqua'
        } 
        return 'white'
    }

    get rankString () {
        return `RANK: #${this.rank}`
    }
}


