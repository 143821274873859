








import { Component, Vue, Prop } from 'vue-property-decorator';
import RarityCheckerComp from '@/components/RarityCheckerComp.vue';

@Component({
  components: {
      RarityCheckerComp
  },
})
export default class RarityChecker extends Vue {


}
