





























import { getTrooperzList } from '@/apiCaller/trooperzApiCaller';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TrooperzBox from '@/components/TrooperzBox.vue'
import FamilySelect from '@/components/FamilySelect.vue'
import { Family, TrooperzApiResult, TrooperzBoxParams } from '@/type';
import axios from 'axios';
import { getIpfsPath } from '../utils/utilities'

@Component({
  components: {
      TrooperzBox,
      FamilySelect
  },
})
export default class RarityCheckerComp extends Vue {
    search: string | null = null

    itemsPerPage = 52
    trooperzList: TrooperzBoxParams[] = []
    searchTimeOut: string | null = null
    timeOutSearch: any = null
    page = 1
    count = 0
    family: Family | null =  null
    allFamily: Family[] = ['aki', 'shibu', 'kabu', 'juku']
    mounted () {
        this.changeSearch()
    }

    async changeSearch (resetPage = false) {
        let data = []
        let response = null
        this.trooperzList = []
        let restParams: {[key: string]: any} = {}
        if (this.family) {
            restParams.family = this.family
        }
        if (resetPage) {
            this.page = 1
        }
        if (this.search) {
            response = await getTrooperzList(this.limit, this.offset, this.search, restParams)
            data = response.data
        } else {
            response = await getTrooperzList(this.limit, this.offset, null, restParams)
            data = response.data
        }
        this.count = response.count
        data.forEach(async (item, index) => {
            let res = await this.apiResultToTrooperzBox(item)
            if (res.rank) {
                Vue.set(this.trooperzList, index, res)
            }
        })
    }

    get limit() {
        return this.itemsPerPage
    }

    get offset () {
        return (this.page - 1) * this.itemsPerPage
    }

    async apiResultToTrooperzBox(apiResult: TrooperzApiResult): Promise<TrooperzBoxParams> {
        let ipfsHash = apiResult.ipfs_hash
        const path = getIpfsPath('ipfs://' + ipfsHash)
        const data = await axios.get(path)
        let metadata = data.data
        return {
            token_id: apiResult.token_id,
            metadata: metadata,
            upgradablesAttributes: apiResult.upgradable_attributes,
            rank: apiResult.rank
        }
    }

    get pageLength () {
        if (!this.count || !this.itemsPerPage) {
            return 0
        }
        return Math.round(this.count / this.itemsPerPage)
    }

    @Watch('family')
    async onChangeFamily () {
        await this.changeSearch(true)
    }

    @Watch('page')
    async onChangePage () {
        await this.changeSearch()
    }

    @Watch('search')
    async onChangeSearch () {
        await this.changeSearch(true)
    }

    @Watch('searchTimeOut') 
    async onChangeSearchTimeOut () {
        if (this.timeOutSearch) {
            window.clearTimeout(this.timeOutSearch)
        }
        this.timeOutSearch = window.setTimeout(() => {
            this.search = this.searchTimeOut
        }, 800)
    }
}
